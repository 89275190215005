import { Component, Watch } from 'vue-property-decorator';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { PrepressTaskList, PrepressTaskListQuery, TakeOrderParam } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  translation,
  dateFormat,
  downloadFileByBlob,
  isNullOrUndefinedForBaseType,
  getFileExtension,
  messageErrors,
  debounce
} from '@/utils';
import {
  CloseStatusEnum,
  PageQueryPlanEnum,
  PrepressChangeColorCraftsEnum,
  PrepressStatusEnum,
  PrepressTypeEnum,
  ProjectProductStatusEnum
} from '@/resource/enum';
import { customerService, changeColorTaskService, projectProductService, minioService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import PrepressTaskDetails from '../prepress-task-details/prepress-task-details.vue';
import { Message } from 'element-ui';
import Redeploy from '../redeploy/redeploy.vue';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { NormalSelectOptions } from '@/resource/model/common';
import { SortOptions } from '@/api/base';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import BatchAddColorChangeSample from '../color-change-sample/batch-add-color-change-sample/batch-add-color-change-sample.vue';
import TakeOrders from '../take-orders/take-orders.vue';
@Component({
  name: 'ChangeColorTask',
  components: { PrepressTaskDetails, BatchAddColorChangeSample, Redeploy, ElImageViewer, TakeOrders }
})
export default class ChangeColorTask extends mixins(PagingMixin, CustomColumnMixin) {
  public type = PrepressTypeEnum;
  public tableOption: OsTableOption<PrepressTaskList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'requiredDeliveryTime' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<PrepressTaskList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'prepress.itemCode',
      minWidth: '230px',
      sortable: 'custom',
      showOverflowTooltip: true,
      fixed: true
    },
    // {
    //   prop: 'thumbnails',
    //   label: 'prepress.thumbnails',
    //   minWidth: '120px',
    //   showOverflowTooltip: true
    // },
    {
      prop: 'projectName',
      label: 'prepress.projectName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'project.customer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'prepress.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'visibleHeight',
      label: 'prepress.visibleSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as PrepressTaskList).visibleWidth} × ${(row as PrepressTaskList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'prepress.finishSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as PrepressTaskList).finishWidth} × ${(row as PrepressTaskList).finishHeight}`;
      }
    },
    {
      prop: 'topPocketSize',
      label: 'prepress.topPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'bottomPocketSize',
      label: 'prepress.bottomPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'count',
      label: 'prepressTask.count',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'prepressSubmitCount',
      label: 'projectProduct.prepressSubmitCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },

    {
      prop: 'screenArea',
      label: 'prepressTask.screenArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'screenRate',
      label: 'prepressTask.screenRate',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'prepress.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'prepress.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'prepress.requiredDeliveryTime',
      minWidth: '180px',
      sortable: 'custom',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as PrepressTaskList).prepressCrafts) {
          return '--';
        }

        return translation(
          `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as PrepressTaskList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'prepressPicture',
      label: 'prepress.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'prepress.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'prepress.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressStatus',
      label: 'prepress.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'closeStatus',
      label: 'projectProduct.closeStatus',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as PrepressTaskList).closeStatus === 1
          ? translation('closeStatus.closed')
          : translation('closeStatus.normal');
      }
    },
    {
      prop: 'makeUserName',
      label: 'prepress.makeUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'makeStateTime',
      label: 'prepress.makeStateTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).makeStateTime);
      }
    },
    {
      prop: 'createUserName',
      label: 'common.createUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'project.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).createTime);
      }
    },
    {
      prop: 'attritionFlag',
      label: 'projectProduct.isConsumeRemake',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (isNullOrUndefinedForBaseType((row as PrepressTaskList).attritionFlag)) {
          return '--';
        }
        return translation(`common.${(row as PrepressTaskList).attritionFlag === 0 ? 'no' : 'yes'}`);
      }
    },
    {
      prop: 'dutyReason',
      label: 'consumed.consumedReason',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionCode',
      label: 'projectProduct.consumedCode',
      minWidth: '150',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      // 新建改色样
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.addChangeColorSample',
      operationType: 'addChangeColorSample',
      disabled: true,
      icon: 'el-icon-plus',
      permissionCode: 'color:change:manage:save',
      handleClick: this.batchAddChangeColorSample
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.takeOrder',
      operationType: 'confirm',
      icon: 'el-icon-check',
      disabled: true,
      permissionCode: 'color:artis:task:receivingOrder',
      handleClick: this.takeOrders
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'color:artis:task:export',
      handleClick: this.export
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<PrepressTaskListQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'prepress.itemCode',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'projectName',
      label: 'prepress.projectName',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'prepress.platformProduct',
      option: {
        placeholder: 'consumed.inputPlatformProduct'
      }
    },
    {
      type: 'Input',
      field: 'prepressPicture',
      label: 'prepress.prepressPicture',
      option: {
        placeholder: 'prepress.inputPrepressPicture'
      }
    },
    {
      type: 'Select',
      field: 'urgentOrderFlag',
      label: 'order.urgentOrder',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'prepressTask.deliveryTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Select',
      field: 'prepressStatusList',
      label: 'prepressTask.billStatus',
      option: {
        placeholder: 'prepressTask.selectBillStatus',
        multiple: true,
        clearable: true
      },
      optionData: (): NormalSelectOptions => {
        return projectProductService.getPrepressStatus().filter(x => x.value !== PrepressStatusEnum.new);
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'project.customer',
      option: {
        placeholder: 'project.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'project.orderCreator',
      option: {
        placeholder: 'project.selectCreator'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'orderCreateTime',
      label: 'order.createDate',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'Input',
      field: 'visibleWidth',
      label: 'projectProduct.visibleWidth',
      option: {
        placeholder: 'customerPoint.inputVisibleWidth'
      }
    },
    {
      type: 'Input',
      field: 'visibleHeight',
      label: 'projectProduct.visibleHeight',
      option: {
        placeholder: 'customerPoint.inputVisibleHeight'
      }
    },
    {
      type: 'Input',
      field: 'finishWidth',
      label: 'projectProduct.finishWidth',
      option: {
        placeholder: 'customerPoint.inputFinishWidth'
      }
    },
    {
      type: 'Input',
      field: 'finishHeight',
      label: 'projectProduct.finishHeight',
      option: {
        placeholder: 'customerPoint.inputFinishHeight'
      }
    },
    {
      type: 'Select',
      field: 'isOverdue',
      label: 'projectProduct.isOverdue',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.overdue'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'closeStatus',
      label: 'projectProduct.closeStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('closeStatus.normal'),
            value: 0
          },
          {
            label: translation('closeStatus.closed'),
            value: 1
          }
        ];
      }
    }
  ];

  /**
   * 控制详情dialog显隐
   */
  public detailsVisible = false;
  /**
   * 控制接单dialog
   */
  public takeOrdersVisible = false;

  public tips: string = '';
  public takeOrdersObj: {
    tips: string;
    showTip: boolean;
    save: Function;
    ids: Array<number>;
  } = {
    tips: '',
    showTip: false,
    ids: [],
    save: this.takeOrder
  };
  /**
   * 控制批量修改改色样
   */
  public batchChangeColorVisible = false;

  public editList: Array<{ id: number; customerName: string }> = [];

  /**
   * 印前单据主键
   */
  public prepressId: number | null = null;

  public selectedRows: Array<PrepressTaskList> = [];

  public redeployVisible = false;

  public redeployLoading = false;

  public selectedIds: Array<number> = [];

  /**
   * 展示确稿预览文件
   */
  public showThumbnails = false;

  /**
   * 要预览的图片列表
   */
  public previewImgList: Array<string> = [];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.changeColorTask;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  private queryForm: Partial<PrepressTaskListQuery> = {};
  private defaultQueryForm: Partial<PrepressTaskListQuery> = {
    createUserName: '',
    customerId: undefined,
    finishHeight: undefined,
    finishWidth: undefined,
    itemCode: '',
    orderCreateTime: [],
    platformProductName: '',
    prepressPicture: '',
    prepressStatusList: [],
    projectName: '',
    requiredDeliveryTime: [],
    urgentOrderFlag: undefined,
    visibleHeight: undefined,
    visibleWidth: undefined,
    isOverdue: undefined,
    closeStatus: undefined
  };

  private sortOptions: SortOptions<PrepressTaskList> = this.tableOption.defaultSort!;

  public get area(): string {
    return this.selectedRows
      .reduce((res, curr) => res + (curr.finishWidth / 1000) * (curr.finishHeight / 1000) * curr.count, 0)
      .toFixed(3);
  }
  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'change-color-task');
    this.getCustomers();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.changeColorTable as OsTable).clearSelection();
    this.selectedRows = [];
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    (this.$refs.changeColorTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<PrepressTaskList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(project: PrepressTaskList): void {
    this.detailsVisible = true;
    this.prepressId = project.id;
  }

  public handleSelectionChange(selectedData: Array<PrepressTaskList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusClass(task: PrepressTaskList): string {
    switch (task.prepressStatus) {
      case PrepressStatusEnum.waitForProduction:
        return 'danger-dot';
      case PrepressStatusEnum.inProduction:
        return 'primary-dot';
      case PrepressStatusEnum.waitForConfirm:
        return 'warning-dot';
      case PrepressStatusEnum.waitComplete:
        return 'warning-dot';
      default:
        return 'info-dot';
    }
  }

  public getStatusName(task: PrepressTaskList): string {
    if (!task.prepressStatus) {
      return translation('common.unKnownStatus');
    }
    return `prepressStatus.${PrepressStatusEnum[task.prepressStatus]}`;
  }

  /**
   * 新建改色样
   */
  public addChangeColorSample(): void {
    const ids = this.selectedRows
      .filter(x =>
        [PrepressStatusEnum.inProduction, PrepressStatusEnum.waitForConfirm, PrepressStatusEnum.waitComplete].includes(
          x.prepressStatus
        )
      )
      .map(x => {
        return {
          id: x.id,
          customerName: x.customerName
        };
      });
    if (ids.length === 0) {
      Message.warning(translation('prepressTask.selectMatchedRequire'));
      return;
    }
    this.$router.push({
      path: 'add-change-color-sample',
      query: {
        id: ids[0].id.toString(),
        type: 'add',
        customerName: ids[0].customerName
      }
    });
  }

  public batchAddChangeColorSample(): void {
    this.editList = this.selectedRows
      .filter(x =>
        [PrepressStatusEnum.inProduction, PrepressStatusEnum.waitForConfirm, PrepressStatusEnum.waitComplete].includes(
          x.prepressStatus
        )
      )
      .map(x => {
        return {
          id: x.id,
          customerName: x.customerName
        };
      });
    if (this.editList.length === 0) {
      Message.warning(translation('prepressTask.selectMatchedRequire'));
      return;
    }
    this.batchChangeColorVisible = true;
  }

  /**
   * 转派  废弃
   */
  public openRedeployDialog(): void {
    this.selectedIds = this.selectedRows
      .filter(x => x.prepressStatus !== PrepressStatusEnum.waitForProduction)
      .map(x => x.id);
    if (this.selectedIds.length === 0) {
      Message.warning(translation('prepressTask.unMatchedRedeployStatus'));
      return;
    }
    this.redeployVisible = true;
  }

  /**
   * 反完成 废弃
   */
  public cancelComplete(): void {
    const errorList: Array<string> = this.selectedRows
      .filter(
        x => x.prepressStatus <= PrepressStatusEnum.inProduction || x.makeStatus === ProjectProductStatusEnum.completed
      )
      .map(x => x.itemCode);
    if (errorList.length > 0) {
      Message.warning(
        `${translation('prepressTask.unMatchedCompleteStatus')}:${errorList.map(item => item).join(',')}`
      );
      return;
    }
    const ids = this.selectedRows.map(x => x.id);
    this.tableOption.loading = true;
    changeColorTaskService
      .cancelComplete(ids)
      .then(() => {
        this.reloadData();
        Message.success(translation('operationRes.operationSuccess'));
      })
      .catch(error => {
        messageErrors(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 接单
   */
  public takeOrders(): void {
    const ids = this.selectedRows
      .filter(
        x =>
          [PrepressStatusEnum.waitForProduction, PrepressStatusEnum.inProduction].includes(x.prepressStatus) &&
          x.closeStatus === CloseStatusEnum.normal
      )
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('prepressTask.unMatchedWaitProduction'));
      return;
    }
    const inProductionIds = this.selectedRows
      .filter(
        x => [PrepressStatusEnum.inProduction].includes(x.prepressStatus) && x.closeStatus === CloseStatusEnum.normal
      )
      .map(x => x.id);
    const inProductionTip = this.selectedRows
      .filter(
        x => [PrepressStatusEnum.inProduction].includes(x.prepressStatus) && x.closeStatus === CloseStatusEnum.normal
      )
      .map(x => x.itemCode)
      .join('、');
    if (inProductionIds.length > 0) {
      this.takeOrdersObj.tips = `单据${inProductionTip}已处于制作中，是否确认继续接单？`;
      this.takeOrdersObj.showTip = true;
    } else {
      this.takeOrdersObj.tips = '';
      this.takeOrdersObj.showTip = false;
    }
    this.takeOrdersObj.ids = ids;
    this.takeOrdersVisible = true;
  }
  public takeOrder(params: TakeOrderParam): Promise<void> {
    return new Promise((resolve, reject) => {
      changeColorTaskService
        .takeOrder(params)
        .then(() => {
          resolve();
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * 确稿图片预览
   * @param fileName
   * @returns
   */
  public async thumbnailsPreview(fileName: string): Promise<void> {
    try {
      await minioService.init();
      const url = await minioService.getDownLoadPath(fileName);
      if (getFileExtension(fileName) === 'pdf') {
        const newWindow = window.open();
        newWindow?.document.write(
          `<iframe width="100%" height="100%" src="${url}" frameborder="0" allowfullscreen></iframe>`
        );
        return;
      }
      // 先清空
      this.previewImgList = [];
      this.previewImgList.push(url);
      this.showThumbnails = true;
    } catch (error) {
      messageError(error as any);
    }
  }

  private loadData(): void {
    this.tableOption.loading = true;
    changeColorTaskService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data.map(x => {
          x.screenArea = ((x.finishWidth / 1000) * (x.finishHeight / 1000)).toFixed(6);
          x.screenRate = (x.visibleWidth / x.visibleHeight).toFixed(3);
          return x;
        });
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const ids = this.selectedRows.map(x => x.id);
      const blob = await changeColorTaskService.export(this.queryForm, ids);
      downloadFileByBlob(`${translation('prepressTask.exportChangeColorTask')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.orderCreateTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleRequiredDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<PrepressTaskList>): void {
    this.operationOptions.forEach(x => {
      if (!['export', 'addChangeColorSample'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
      if (['addChangeColorSample'].includes(x.operationType)) {
        x.disabled = !(value.length > 0);
      }
    });
  }
}
