import { OsTable } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { DialogMixin } from '@/mixins/dialog';
import {
  NumberAssignmentTypeEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressTypeEnum,
  SelectAssignmentTypeEnum,
  StringAssignmentTypeEnum
} from '@/resource/enum';
import { FieldOption } from '@/resource/model';
import { dateFormat, decimalToNumber6, messageErrors, translation } from '@/utils';

import { Message, Table } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { BatchSetupData } from '@/views/dialogs';

import { colorChangeSampleService, materialListService, projectProductService } from '@/api';
import { ApiError } from '@/api/axios';
import { cloneDeep } from 'lodash-es';
import { ColorChangeSample, ColorChangeSampleList } from '@/resource/model/prepress-task/prepress-task';
import Decimal from 'decimal.js';

@Component({
  name: 'batchAddColorChangeSample',
  components: {
    BatchSetupData
  }
})
export default class BatchAddColorChangeSample extends mixins(DialogMixin, CustomColumnMixin) {
  /**
   * 要改稿的数据
   */
  @Prop({
    required: false,
    type: Array,
    default: () => {
      return [];
    }
  })
  public editList!: Array<{ id: number; customerName: string }>;

  public dialogVisible = false;

  public pageTotal = 0;
  public tableOption: OsTableOption<ColorChangeSample> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true
  };
  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.batchUpdate',
      operationType: 'batchUpdate',
      disabled: true,
      handleClick: (): void => {
        this.openDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'batchSetupData.remove',
      operationType: 'delete',
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];
  /**
   * table行的操作配置
   */
  // public rowOperationOptions: RowOperation<PrepressTaskInfo> = {
  //   fixed: 'right',
  //   width: '80px',
  //   operations: [
  //     {
  //       operationType: 'edit',
  //       type: 'text',
  //       label: 'button.edit',
  //       icon: 'el-icon-edit',
  //       permissionCode: 'project:item:edit',
  //       handleClick: (row: PrepressTaskInfo): void => {
  //         this.openEditDialog(row);
  //       }
  //     }
  //   ]
  // };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<ColorChangeSample & { round: number; screenRate: number }>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'prepress.itemCode',
      minWidth: '230px',
      sortable: 'custom',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'customerName',
      label: 'project.customer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'prepress.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'platformProductName',
      label: 'prepress.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'prepress.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'prepress.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ColorChangeSample).visibleWidth} × ${(row as ColorChangeSample).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'prepress.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ColorChangeSample).finishWidth} × ${(row as ColorChangeSample).finishHeight}`;
      }
    },
    {
      prop: 'count',
      label: 'prepressTask.count',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    // {
    //   prop: 'unitArea',
    //   label: 'projectProduct.unitArea',
    //   minWidth: '120px',
    //   showOverflowTooltip: true
    // },
    {
      prop: 'totalArea',
      label: 'billing.totalArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'screenRate',
      label: 'prepressTask.screenRate',
      minWidth: '120px',
      showOverflowTooltip: true
    },

    {
      prop: 'requiredDeliveryTime',
      label: 'prepress.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ColorChangeSampleList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ColorChangeSampleList).prepressCrafts) {
          return '--';
        }
        if ((row as ColorChangeSampleList).itemType === PrepressTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as ColorChangeSampleList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as ColorChangeSampleList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'prepressPicture',
      label: 'prepress.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'prepress.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'prepress.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'printingFlag',
      label: 'projectProduct.printingFlag',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return this.flagName((row as ColorChangeSampleList).printingFlag);
      }
    },
    {
      prop: 'backendFlag',
      label: 'projectProduct.backendFlag',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return this.flagName((row as ColorChangeSampleList).backendFlag);
      }
    }
  ];
  public selectedRows: Array<ColorChangeSample> = [];
  /**
   * 字段批量修改配置项
   */
  public fieldOptions: Array<any> = [
    {
      fieldName: 'count',
      fieldLabel: '数量',
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'finishWidth',
      fieldLabel: '完成宽度',
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'finishHeight',
      fieldLabel: '完成高度',
      disabled: false,
      fieldType: 'number'
    },

    {
      fieldName: 'remark',
      fieldLabel: '备注信息',
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'printingFlag',
      fieldLabel: '输出工序',
      disabled: false,
      fieldType: 'select'
    },
    {
      fieldName: 'backendFlag',
      fieldLabel: '后道工序',
      disabled: false,
      fieldType: 'select'
    }
  ];

  public flagName(flag: number): string {
    return flag ? translation('projectProduct.flagOpen') : translation('projectProduct.flagClose');
  }
  public created(): void {}

  public dialogOpen(): void {
    this.tableOption.data = [];
    const ids = this.editList.map(x => x.id);
    const arr: Array<ColorChangeSample> = [];
    projectProductService.getBatchById(ids).then(results => {
      results.forEach(item => {
        const colorChangeSample: ColorChangeSample = {} as ColorChangeSample;
        if (item.pocketFlag === 1) {
          const remarkArr = [];
          if (item.remark) {
            remarkArr.push(item.remark);
          }

          if (item.topPocketSize > 0) {
            remarkArr.push("上口袋尺寸" + item.topPocketSize + "cm");
          }

          if (item.bottomPocketSize > 0) {
            remarkArr.push("下口袋尺寸" + item.bottomPocketSize + "cm");
          }

          item.remark = remarkArr.join("，");
        }
        Object.assign(colorChangeSample, item);
        colorChangeSample.id = null;
        colorChangeSample.itemId = item.id;
        this.calculateUnitArea(colorChangeSample);
        arr.push(colorChangeSample);
      });
      this.getProductionInfo(arr);
    });
  }

  /**
   * 获取工序
   * @param item
   */
  public async getProductionInfo(tableData: Array<ColorChangeSample>): Promise<void> {
    const platformProductIds = tableData.map(x => x.platformProductId);
    const platformProductList = await materialListService.getListForProductionComponents(platformProductIds);
    platformProductList.forEach(item => {
      tableData.forEach(cur => {
        if (cur.platformProductId === item.id) {
          const { printingFlag, backendFlag } = item;
          Object.assign(cur, { printingFlag, backendFlag });
        }
      });
    });
    this.tableOption.data.push(...tableData);
  }

  /**
   *
   * @param item 计算面积
   */
  public calculateUnitArea(item: ColorChangeSample): void {
    item.unitArea =
      decimalToNumber6(new Decimal(item.finishWidth as number).mul(new Decimal(item.finishHeight as number))) /
        1000000 || 0;
    item.totalArea = decimalToNumber6(new Decimal(item.unitArea as number).mul(new Decimal(item.count as number)));
    // 可见尺寸等于完成尺寸
    item.visibleWidth = item.finishWidth;
    item.visibleHeight = item.finishHeight;
    item.screenRate = (item.visibleWidth! / item.visibleHeight!).toFixed(3);
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  // 批量修改字段值
  public openDialog(): void {
    if (this.selectedRows.length === 0) {
      Message.warning(translation('batchSetupData.selectDataTip'));
      return;
    }
    this.dialogVisible = true;
  }

  public batchDelete(): void {
    if (this.selectedRows.length === 0) {
      Message.warning(translation('batchSetupData.selectDataTip'));
      return;
    }
    if (this.selectedRows.length === this.tableOption.data.length) {
      Message.warning(translation('common.keepDataTip'));
      return;
    }
    this.tableOption.data = this.tableOption.data.filter(data => !this.selectedRows.includes(data));
    this.clearSelection();
  }

  /**
   * 表格勾选事件
   */
  public handleSelectionChange(selectedData: Array<ColorChangeSample>): void {
    this.selectedRows = [...selectedData];
  }

  /**
   * 批量修改赋值
   */
  public setData(field: Array<FieldOption>): void {
    // console.log('填入的数据', JSON.stringify(field));
    this.selectedRows.forEach(item => {
      const entries = new Map(Object.entries(item));
      field.forEach(cur => {
        if (cur.fieldType === 'string') {
          switch (cur.assignmentType) {
            case StringAssignmentTypeEnum.update:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case StringAssignmentTypeEnum.increase:
              entries.set(cur.fieldName, `${entries.get(cur.fieldName) || ''}${cur.fieldValue}`);
              break;
            default:
              break;
          }
        } else if (cur.fieldType === 'number') {
          switch (cur.assignmentType) {
            case NumberAssignmentTypeEnum.equal:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.add:
              entries.set(cur.fieldName, entries.get(cur.fieldName) + cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.sub:
              entries.set(cur.fieldName, entries.get(cur.fieldName) - cur.fieldValue);
              break;
            default:
              break;
          }
        } else if (cur.fieldType === 'select') {
          switch (cur.assignmentType) {
            case SelectAssignmentTypeEnum.update:
              if (cur.fieldValue === '') break;
              entries.set(cur.fieldName, Number(cur.fieldValue));
              break;
            default:
              break;
          }
        }
        Object.assign(item, Object.fromEntries(entries));
      });
      // 计算单位面积
      this.calculateUnitArea(item);
    });
    this.clearSelection();
    this.dialogVisible = false;
  }

  /**
   * 数据校验
   * @returns
   */
  public validate(): boolean {
    if (this.tableOption.data?.length === 0) {
      Message.warning(`${translation('没有可提交的数据')}`);
      return false;
    }
    const errors: Array<ApiError> = [];
    this.tableOption.data?.forEach(product => {
      let message = '';
      const { finishWidth, finishHeight, remark, count, backendFlag, printingFlag } = product as Partial<
        ColorChangeSample
      >;
      if (!finishWidth || finishWidth <= 0 || !finishHeight || finishHeight <= 0) {
        message = `[${translation('projectProduct.inputFinishize')}]`;
      }
      if (remark!.length > 200) {
        message = message + `[备注信息：超出长度范围]`;
      }

      if (!count || count <= 0) {
        message = message + `[制作数量：要求大于等于1]`;
      }
      if (backendFlag === 0 && printingFlag === 0) {
        message = message + `[工序：至少开启一个]`;
      }

      if (message) {
        errors.push({
          message: `【${product.itemCode}】: ${message} `,
          level: 1
        });
      }
    });
    if (errors.length > 0) {
      messageErrors(errors);
      return true;
    }
    return false;
  }

  public async onSubmit(): Promise<void> {
    this.setLoading(true);
    // 校验数据
    if (this.validate()) {
      this.setLoading(false);
      return;
    }

    const reedit = cloneDeep(this.tableOption.data);
    try {
      this.setLoading(true);
      await colorChangeSampleService.post(reedit);
      Message.success(translation('operationRes.addSuccess'));
      this.$emit('edit-success');
      this.closeDialog();
    } catch (error) {
      if (error) messageErrors(error);
    } finally {
      this.setLoading(false);
    }
    this.syncedVisible = false;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    ((this.$refs.batchAddColorChangeSampleTable as OsTable).tableRef as Table).clearSelection();
  }
  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ColorChangeSample>): void {
    const dynamicBtnTypes: Array<string> = ['batchUpdate', 'delete'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnTypes.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
}
